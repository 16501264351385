@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fadeIn {
  animation: fadeIn 2s ease-out forwards;
}

@keyframes fillCooldown {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* TailwindCSS classes for each highlight type */
.highlighted-open {
  @apply bg-green-100 text-green-800;
}

.highlighted-done {
  @apply bg-gray-200 dark:bg-neutral-700 text-gray-600;
}

.highlighted-question {
  @apply bg-blue-100 text-blue-800;
}

.highlighted-note {
  @apply bg-green-100 text-green-800;
}

.highlighted-fallacy {
  @apply bg-yellow-100 text-yellow-800;
}

.highlighted-nugget {
  @apply bg-purple-100 text-purple-800;
}

.highlighted-manual {
  @apply bg-gray-100 text-gray-800;
}


.tooltip {
  @apply absolute bg-white shadow-lg border p-4 rounded-lg z-50 max-w-xs text-sm;
}


.capitalize-first::first-letter {
  text-transform: uppercase;
}

body {
  -webkit-perspective: none;
  -webkit-transform-style: flat;
}
body > * {
  -webkit-backface-visibility: visible;
  -webkit-transform: none;
}

body {
  @apply overflow-hidden touch-none;
}

.ProseMirror {
  @apply h-full overflow-y-auto touch-pan-y;
}

/* Add to your global styles */
.transcription-container {
  scroll-behavior: smooth;
}

/* For performance, disable smooth scrolling during programmatic scroll restoration */
.transcription-container.restoring-scroll {
  scroll-behavior: auto;
}